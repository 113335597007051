.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-left: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 35%;
  min-width: 35rem;
}
.addList /deep/ .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addList .card-border {
  padding: 1rem;
  margin-top: 1rem;
  background: #f3f3f3;
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 32px;
}
.shopDecorate .phone_top {
  padding: 20px 13px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.shopDecorate .phone_top .setBox {
  border: 1px solid #dcdfe6;
  padding: 5px 8px;
  border-radius: 20px;
}
.shopDecorate .phone_bot {
  border-top: #000 solid 1px;
  padding: 10px 13px 20px;
}
.shopDecorate .phonebox {
  width: 375px;
  min-height: 500px;
  height: 750px;
  border: 1px solid #000;
  border-radius: 40px;
}
.shopDecorate .phonebox .top {
  position: relative;
  z-index: 100;
  cursor: pointer;
}
.shopDecorate .phonebox > .phone_content {
  flex: 1;
  padding: 0 13px;
  margin-bottom: 10px;
}
.shopDecorate .phonebox > .phone_content > div {
  position: relative;
  margin-bottom: 10px;
}
.shopDecorate .phonebox .phone_banner {
  min-height: 150px;
  text-align: center;
  background-color: #999;
}
.shopDecorate .phonebox .phone_application {
  width: 100%;
  min-height: 115px;
}
.shopDecorate .phonebox .phone_application .application_item {
  width: 25%;
  margin-bottom: 5px;
}
.shopDecorate .phonebox .phone_application .application_item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.shopDecorate .phonebox .pushBanner {
  width: 100%;
  height: 120px;
}
.shopDecorate .phonebox .Selected .course_list {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #aaa;
}
.shopDecorate .phonebox .Selected .course_list .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.shopDecorate .phonebox .Selected .course_list .informationDate {
  padding-top: 10px;
  color: #aaa;
}
.shopDecorate .modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(203, 209, 255, 0.4);
  z-index: 99;
  width: 100%;
  height: 100%;
  border: 2px dashed #001bff;
  display: flex;
  justify-content: flex-end;
}
.shopDecorate .modal div {
  align-self: flex-end;
}
.shopDecorate .modal div img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
.information-container {
  padding: 1rem;
  background: #f3f3f3;
}
.information-container .formation {
  padding: 1rem;
  background: #fff;
  margin-top: 1rem;
}
.information-container .container {
  position: relative;
}
.information-container .deleteBtn {
  position: absolute;
  right: 23px;
  bottom: 12%;
}
.information-container .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.information-container .informationDate {
  padding-top: 10px;
  color: #aaa;
}
